import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import * as config from "./config";


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: config.requestIp,

  // 超时
  timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  if (getToken() && getToken() != 'undefined' == true) {
    if (getToken().indexOf('Bearer') !== -1) {
      config.headers['Authorization'] = getToken()// 让每个请求携带自定义token 请根据实际情况自行修改
    } else {
      config.headers['Authorization'] = `Bearer ${getToken()}`
    }
  } else {
    config.headers['Authorization'] = localStorage.getItem('token')
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && typeof (value[key]) !== 'undefined') {
              let params = propName + '[' + key + ']';
              let subPart = encodeURIComponent(params) + '=';
              url += subPart + encodeURIComponent(value[key]) + '&';
            }
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || res.data.status || 0;
  // 获取错误信息
  const msg = errorCode[code] || res.data.message || errorCode['default']
  // const data = res.data.data
  if (code === 502) {
    Message({
      message: '无效的会话，或者会话已过期，请重新登录。',
      type: 'error'
    })
    if (window.location.pathname === '/city' || window.location.pathname === '/newDistrict') {
      store.dispatch('FedLogOut').then(() => {
        // location.href = 'https://csiva.terabits.cn'
        location.href = 'https://zjiva.terabits.cn';
      })
    }
    // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
    //   confirmButtonText: '重新登录',
    //   cancelButtonText: '取消',
    //   type: 'warning'
    // }
    // ).then(() => {
    //   store.dispatch('FedLogOut').then(() => {
    //     location.href = '/login';
    //   })
    // }).catch(() => { });
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  }
  else if (code === 1000) {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      store.dispatch('FedLogOut').then(() => {
        location.href = '/infantlogin';
      })
    }).catch(() => { });
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  }
  else if (code === 40163) {
    Notification.error({
      title: 'code失效，请重新尝试'
    })
  }
  else if (code === 10012) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(res.data)
  }
  else if (code === 10011) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(res.data)
  } else if (code === 10010) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(res.data)
  }
  else if (code === 5) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(res.data)
  } else if (code === -1) {
    Message({
      message: '无效的会话，或者会话已过期，请重新登录。',
      type: 'error'
    })
    if (window.location.pathname === '/city' || window.location.pathname === '/district') {
      store.dispatch('FedLogOut').then(() => {
        // location.href = 'https://csiva.terabits.cn'
        location.href = 'https://zjiva.terabits.cn';
      })
    }
    return Promise.reject(res.data)
  }
  else if (code !== 0 && code !== 1) {
    Notification.error({
      title: '返回错误，请重新尝试'
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      // message = "系统接口" + message.substr(message.length - 3) + "异常";
      if (message.substr(message.length - 3) === '401') {
        Message({
          message: '无效的会话，或者会话已过期，请重新登录。',
          type: 'error'
        })
        // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        //   confirmButtonText: '重新登录',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }
        // ).then(() => {

        // console.log('window', window.location.pathname)
        if (window.location.pathname === '/city' || window.location.pathname === '/district') {
          store.dispatch('FedLogOut').then(() => {
            // location.href = 'https://csiva.terabits.cn'
            location.href = 'https://zjiva.terabits.cn';
          })
        }
        // }).catch(() => { });
      }
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
