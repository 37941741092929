import request from '../utils/request'
// import * as config from "../utils/config";datav/countStatistic
import store from '../store'
// console.log('store :>> ', store);
// const IP = config.environment === 'TE' ? 'https://csiva.terabits.cn:9091' : 'https://zjiva.terabits.cn:9091/'
const Authorization = store.getters.token||localStorage.getItem('token')
// 获取酒店接入数据
const getEnter = () => request.get('/statistics/siteTypeStatistic')
// 洁具布草
const getRight = () => request.get(`/dataV/workStatistical`)
// 获取左侧统计数量
const getCountStatistic = () => request.get(`/datav/countStatistic?Authorization=${Authorization}`)
// 杯具洗消
const getCup = () => request.get(`/datav/areaStatisticsByWeek?Authorization=${Authorization}`)
// 获取亚运会数据
const getAsiad = () => request.get('/statistics/siteIsAsianGames')
// 风险推送
const getAlarm = () => request.get(`/datav/alarm?Authorization=${Authorization}&pageNum=1&pageSize=50`)
// 酒店大屏最近消毒记录
const getHotelRecord = (start, stop) => request.get(`/datav/cleanRecord?Authorization=${Authorization}&start=${start}&stop=${stop}`)
// 区县酒店分布
const getAreaSite = () => request.get(`/datav/areaSiteStatistics?Authorization=${Authorization}`)
// 获取酒店分布
const getAreaList = () => request.get(`/datav/areaSiteStatisticsWithSite?Authorization=${Authorization}`)
// 获取工作汇总数据
const getWorkTotal = (start, stop) => request.get(`/riskClue/count?start=${start}&stop=${stop}`)

// 获取用户信息
const getAreaMsg = () => request.post(`/infra-auth/auth/currentUser`)
// 获取本周提醒汇总
const getWeekRemind = () => request.get(`/datav/alarmWeekCount?Authorization=${Authorization}`)
// 最近消毒记录列表
const getSterilizeList = (start, stop) => request.get(`/datav/cleanRecord?Authorization=${Authorization}&start=${start}&stop=${stop}`)
// 最近消毒识别视频
const getSterilizeVideo = () => request.get(`/datav/videoUrl?Authorization=${Authorization}`)
// 一周风险趋势
const getWeekLevel = () => request.get(`/datav/alarmLevelCount?Authorization=${Authorization}`)
// 本月累计消毒情况
const getTotalSterilize = (start, stop) => request.get(`/statistics/simpleAreaStatistics?start=${start}&stop=${stop}`)

// 空气大屏相关接口
// 本月累计报警次数
const getMonthAlarm = () => request.get(`/api/v1/alarm/totalAlarmCount?access_token=${Authorization}`)
// 实时数据排行
const getRealTimeRank = (alarmType) => request.get(`/api/v1/event/rank?access_token=${Authorization}&alarmType=${alarmType}`)
// 相关统计
const getAll = () => request.get(`/api/v1/unit/countOfUnitAndDevice?access_token=${Authorization}`)
// 最新报警数据
const getNewAlarm = () => request.get(`/api/v1/alarm/latestList?access_token=${Authorization}&pageNum=1&pageSize=50`)
// 实时监测数据
const getRealTimeData = () => request.get(`/api/v1/event/avgStatistics?access_token=${Authorization}`)
// 近七天监测数据
const getWeekData = (type) => request.get(`/api/v1/event/weekStatistics?access_token=${Authorization}&type=${type}`)
// 本月影院报警排行
const getMonthRank = () => request.get(`/api/v1/alarm/countRankOfUnit?access_token=${Authorization}`)
// 获取所有单位坐标
const getAllLocation = () => request.get(`/api/v1/unit/allCoord?access_token=${Authorization}`)
// 获取用户信息
const getUser = () => request.get(`/api/v1/admin/account/getInfo?access_token=${Authorization}`)

// 控烟大屏相关接口
// 最近告警统计
const getAlarmStatistics = () => request.get(`/getNearAlarmList?access_token=${Authorization}&pageNum=1&pageSize=50`)
// 左上角统计
const getSmokeTotal = () => request.get(`/getBaseInfo?access_token=${Authorization}`)
// 单位报警排行
const getUnitRankList = () => request.get(`/getUnitAlarmList?access_token=${Authorization}`)
// 场景单位告警分布
const getSceneAlarm = () => request.get(`/getNearMonthUnitTypeAlarmList?access_token=${Authorization}&dateKey=3`)
// 近半年场景告警分布
const getHalfYear = () => request.get(`/getUnitTypeAlarmList?access_token=${Authorization}`)
// 今日告警数分布
const getTodayAlarm = () => request.get(`/getNearMonthUnitTypeAlarmList?access_token=${Authorization}&dateKey=1`)
// 近一周设备告警统计
const getWeekAlarm = () => request.get(`/getDeviceAlarmList?access_token=${Authorization}&type=1`)
// 视频画面
const getVideo = () => request.get(`/getVideoUrlLimitTen?access_token=${Authorization}`)
// 获取点位
const getLocation = () => request.get(`/getUnitAreaList?access_token=${Authorization}`)
// 获取个人信息
const getItem = () => request.get(`http://47.96.147.50:8013/zs/smoke/api/v1/admin/account/getInfo?access_token=${Authorization}`)

//直饮水相关接口
//设备用水情况
const getflow = () => request.get(`/getflow0`)
//最近一周用水统计
const getweekdatadot = () => request.get(`/getweekdatadot`)

//设备分布统计
const deviceMaintenanceStatistic = () => request.get(`/deviceMaintenanceStatistic?access_token=${Authorization}`)

//设备维护概览
const deviceMaintenanceOverview = () => request.get(`/deviceMaintenanceOverview?access_token=${Authorization}`)

//设备本月报警统计
const alarmStatisticThisMonth = () => request.get(`/alarmStatisticThisMonth?access_token=${Authorization}`)

//本月设备维护数据统计
const deviceMaintenanceThisMonth = () => request.get(`/deviceMaintenanceThisMonth?access_token=${Authorization}`)

//单位统计
const unitStatistic = () => request.get(`/unitStatistic?access_token=${Authorization}`)


// 婴幼儿大屏接口
const getInfant = () => request.get('/dataV/areaRunningInfo')
// 报警管理
const getAlarmInfant = () => request.get('/alarm?pageSize=50&pageNum=1')
// 婴幼儿电视展示页面接口
const getInfantMsg = () => request.get('/dataV/unitRunningInfo')
// 婴幼儿电视登录接口
const infantLogin = (params) => request.post('/login', params, {
  headers: {
    isExpiration: false
  }
})
// 婴幼儿电视获取健康证图片
const getHealthList = () => request.get('/api/v1/person/getList')

// 酒店大屏
// 近一周布草更换记录
const getLinen = () => request.get(`/dataV/getHotelLogRoomCentralization`)
// 洁具清洁图表数据
const getClean = (startTime, endTime) => request.get(`/dataV/getHotelLogStoolDishcloths?startTime=${startTime}&endTime=${endTime}`)
// 获取统计数量
const getCount = () => request.get(`/dataV/getData`)
// 获取图片
const getImg = () => request.get('/site/info')
// 杯具洗消
const getCupmsg = () => request.get(`/datav/countStatistic?Authorization=${Authorization}`)
// 获取保洁员
const getCleaner =()=>request.get('/dataV/getCleanerCount')
// 获取酒店达标率
const getWeekRiskRate =()=>request.get(`/datav/siteWeekRiskRate?Authorization=${Authorization}`)


// 区级大屏
// 获取酒店在线率
const getOnlineRate = (adcode, statDate) => request.get(`/hotelOnLinRate/getRate?adcode=${adcode}&statDate=${statDate}`)
// 计算区域酒店在线率
const getHotelOnLinRate = (adcode, statDate) => request.get(`/hotelOnLinRate/calRateByArea?adcode=${adcode}&statDate=${statDate}`)
// 新版区级大屏
const getHotelWorkList=()=>request.get(`/datav/getHotelCleaningUpStandardList?Authorization=${Authorization}`)
const getHotelWorkNum=()=>request.get(`/datav/getHotelCleaningUpStandardNum?Authorization=${Authorization}`)
// w18 区大屏综合考核率
export function getRate (params) {
  return request({
    url:`/datav/getTotalBusinessDataStatistics`,
    method: 'get',
    params:{
      statType:params[0],
      areaCode:params[1],
      startTime:params[2],
      endTime:params[3],
      Authorization:Authorization
    }
  })
}
// w18 区大屏轮播月考核率
export function getMonthRate (params) {
  return request({
    url:`/datav/getMonthBusinessDataStatistics`,
    method: 'get',
    params:{
      statType:params[0],
      areaCode:params[1],
      startTime:params[2],
      endTime:params[3],
      Authorization:Authorization
    }
  })
}
const getAlarmForAreas = (params) => request.get(`/datav/alarm?Authorization=${Authorization}&pageNum=1&pageSize=50&areas=${params}`)
// w25 省市大屏考核率
export function getStatisticsByParentAreaCode (params) {
  return request({
    url:`/datav/getBusinessDataStatisticsByParentAreaCode`,
    method: 'get',
    params:{
      statTypes:params[0],
      areaCode:params[1],
      startTime:params[2],
      endTime:params[3],
      Authorization:Authorization
    }
  })
}
const getcurrentUser = () => request.post(`/infra-auth/auth/currentUser`)

export {
  getEnter,
  getRight,
  getCountStatistic,
  getCup,
  getAsiad,
  getAlarm,
  getHotelRecord,
  getAreaSite,
  getAreaList,
  getWorkTotal,
  getAreaMsg,
  getWeekRemind,
  getSterilizeList,
  getSterilizeVideo,
  getWeekLevel,
  getTotalSterilize,
  getMonthAlarm,
  getRealTimeData,
  getAll,
  getNewAlarm,
  getRealTimeRank,
  getWeekData,
  getMonthRank,
  getAllLocation,
  getUser,
  getAlarmStatistics,
  getSmokeTotal,
  getUnitRankList,
  getSceneAlarm,
  getHalfYear,
  getTodayAlarm,
  getWeekAlarm,
  getVideo,
  getLocation,
  getItem,
  getflow,
  getweekdatadot,
  deviceMaintenanceStatistic,
  deviceMaintenanceOverview,
  alarmStatisticThisMonth,
  deviceMaintenanceThisMonth,
  unitStatistic,
  getInfant,
  getInfantMsg,
  infantLogin,
  getAlarmInfant,
  getLinen,
  getClean,
  getCount,
  getImg,
  getCupmsg,
  getHealthList,
  getOnlineRate,
  getHotelOnLinRate,
  getCleaner,
  getWeekRiskRate,
  getHotelWorkList,
  getHotelWorkNum,
  getAlarmForAreas,
  getcurrentUser
}
